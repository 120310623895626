import {useQueryNotifications} from 'lib/notification'
import {Tumbleweed} from 'core/components'
import {ActivityCard, ActivityCardLoading, SectionTitle} from 'components'
import {Stack} from 'ui/core'

const RecentActivities = () => {
  const {data, loading} = useQueryNotifications()

  return (
    <div>
      <SectionTitle title="Activities" />
      {loading && (
        <Stack gap="sm">
          {[...new Array(2).keys()].map((i) => (
            <ActivityCardLoading key={i} />
          ))}
        </Stack>
      )}
      {!loading && data?.length ? (
        <Stack gap="sm">
          {data?.slice(0, 2).map((notification) => (
            <ActivityCard key={notification.id} notification={notification} />
          ))}
        </Stack>
      ) : null}
      {!loading && !data?.length ? (
        <Tumbleweed variant="card" tumbleweedContent="activities" />
      ) : null}
    </div>
  )
}

export default RecentActivities
