import {Image} from 'core/components'
import {Product} from 'model'
import {Stack, Text} from 'ui/core'
import classes from './SwapOfferProduct.module.css'

interface SwapOfferProductProps {
  product: Product
}

const SwapOfferProduct = ({product}: SwapOfferProductProps) => (
  <Stack gap={4}>
    <Text ta="center" lineClamp={1}>
      {product?.title}
    </Text>
    <Image
      className={classes.image}
      width={200}
      height={200}
      src={product?.image}
      alt={product.title || ''}
    />
  </Stack>
)

export default SwapOfferProduct
