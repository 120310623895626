import {Catalogue, SectionTitle} from 'components'
import {SwapOffer} from 'model'
import {SwapOfferCard} from './SwapOfferCard'

interface SwapOffersProps {
  swapOffers: SwapOffer[]
}

const SwapOffers = ({swapOffers}: SwapOffersProps) => (
  <div>
    <SectionTitle title="Swap Offers" />
    <Catalogue cols={{sm: 2}}>
      {swapOffers.map((swapOffer) => (
        <SwapOfferCard key={swapOffer.id} swapOffer={swapOffer} />
      ))}
    </Catalogue>
  </div>
)
export default SwapOffers
